<template>
  <v-container
      fluid
  >
    <v-card>
      <v-card-title>
        <h2>Настройки</h2>
        <v-spacer></v-spacer>
      </v-card-title>

      <v-col cols="4">
        <v-file-input
            small-chips
            show-size
            outlined
            @change="changeFile($event)"
            accept="image/png, image/jpeg, image/jpg"
            label="Логотип*"
        ></v-file-input>
        <v-img
            max-width="130px"
            max-height="100px"
            :src="getUrl"
            v-for="(getUrl,index) in getUrlImages"
            :key="index"
        ></v-img>
      </v-col>

      <v-col cols="4">
        <v-text-field
            v-model="getSettings.number1"
            :rules="getRulesTextField(20)"
            outlined
            label="Первая линия (верхний номер)"
            type=string
            required="true"
        ></v-text-field>
      </v-col>

      <v-col cols="4">
        <v-text-field
            v-model="getSettings.number2"
            :rules="getRulesTextField(20)"
            outlined
            label="Вторая линия (нижний номер)"
            type=string
            required="true"
        ></v-text-field>
      </v-col>

      <v-col cols="4">
        <v-text-field
            v-model="getSettings.email"
            :rules="getRulesTextField(9999)"
            outlined
            label="Третья линия (почта)"
            type=string
            required="true"
        ></v-text-field>
      </v-col>

      <v-col cols="4">
        <v-btn
            color="primary"
            @click="add()"
        >
          Сохранить
        </v-btn>
        <v-alert class="mt-4" v-if="getError != null" type="error">{{ getError }}</v-alert>
        <v-alert class="mt-4" v-if="getMessage != null" type="success">{{ getMessage }}</v-alert>
      </v-col>
    </v-card>

  </v-container>
</template>

<script>
export default {
  name: "Settings",
  data() {
    return {
      settings: {},
      error: null
    };
  },
  computed: {
    getError() {
      return this.$store.getters.getError;
    },
    getMessage() {
      return this.$store.getters.getMessage;
    },
    getSettings: {
      get: function () {
        return this.$store.getters.getSettings;
      },
      set: function (newValue) {
        this.settings = newValue;
      }
    },
    getUrlImages() {
      let image = this.getSettings.logo;
      if (image == null || image == "") {
        return "";
      }
      let result = [];
      if (image instanceof Object) {
        for(let i = 0; i < image.length; i++) {
          if (image[i] instanceof File) {
            result.push(URL.createObjectURL(image[i]));
          } else {
            result.push(process.env.VUE_APP_URL_IMAGE+'/'+image[i]);
          }
        }
      }
      else if (image instanceof File) {
        result.push(URL.createObjectURL(image));
      } else {
        let img = image.split("#%@");
        for(let i = 0; i < img.length; i++) {
          result.push(process.env.VUE_APP_URL_IMAGE+'/'+img[i]);
        }
      }

      return result;
    }
  },
  methods: {
    add() {

      this.$store.commit('setError', "Заполните все обязательные поля");

      let formData = new FormData();

      if (typeof this.getSettings.logo !== 'string') {
        formData.append('logo', this.getSettings.logo);
      }

      formData.append('number1', this.getSettings.number1);
      formData.append('number2', this.getSettings.number2);
      formData.append('email', this.getSettings.email);

      this.$store.dispatch('saveSettings', formData);
    },
    changeFile(file) {
      this.getSettings.logo = file;
    },
    getRulesTextField(maxLength, isRequired = true) {
      return [
        v => !!v && isRequired || 'Поле обязательно',
        v => v && v.length <= maxLength || `Введено более ${maxLength} символов`
      ]
    },
  },
  beforeCreate() {
    this.$store.dispatch('getSettings');
  }
}
</script>

<style scoped>

</style>