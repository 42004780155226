import axios from "axios";
import router from "@/router";

export default {
    state: {
        products: [],
        product: {}
    },
    getters: {
        getProducts(state) {
            return state.products;
        },
        getProduct(state) {
            state.product.specifications = state.product.specifications != null
                                        && state.product.specifications != undefined
                                        && (typeof state.product.specifications === "string" || state.product.specifications instanceof String)
                                            ? JSON.parse(state.product.specifications)
                                            : state.product.specifications;
            return state.product;
        }
    },
    mutations: {
        setProducts(state, payload) {
            state.products = payload;
        },
        setProduct(state, payload) {
            if (!payload.specifications)
            {
                payload.specifications = [];
            }
            if (typeof payload.image === "string" || payload.image instanceof String) {
                payload.image = payload.image.split("#%@");
            }
            state.product = payload;
        }
    },
    actions: {
        async getProducts({commit}) {
            commit('setError', "");
            commit('setLoadingTable', true);
            await axios.get('/product/getProducts')
                .then(response => {
                    commit('setLoadingTable', false);
                    if (!response.data.error) {
                        commit('setProducts', response.data)
                    } else {
                        commit('setError', response.data.error)
                    }
                })
                .catch(error => {
                    commit('setLoadingTable', false);
                    if (error.response) {
                        commit('setError', error.response.data);
                    }
                    throw error
                })
        },
        async getProduct({commit}, productId) {
            commit('setError', "");
            await axios.get('/product/getProduct', { params: { productId } })
                .then(response => {
                    if (!response.data.error) {
                        commit('setProduct', response.data)
                    } else {
                        commit('setError', response.data.error)
                    }
                })
                .catch(error => {
                    if (error.response) {
                        commit('setError', error.response.data);
                    }
                    throw error
                })
        },
        async saveProduct({commit}, payload) {
            commit('setError', '');
            await axios.post('/product/save', payload, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(response => {
                    if (!response.data.error) {
                        router.push('/products');
                    } else {
                        commit('setError', response.data.error)
                    }
                })
                .catch(error => {
                    if (error.response) {
                        commit('setError', error.response.data);
                    }
                    throw error
                })
        },
        async editProduct({commit}, payload) {
            commit('setError', '');
            await axios.post('/product/edit', payload, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(response => {
                    if (!response.data.error) {
                        router.push('/products');
                    } else {
                        commit('setError', response.data.error)
                    }
                })
                .catch(error => {
                    if (error.response) {
                        commit('setError', error.response.data);
                    }
                    throw error
                })
        },
        async deleteProduct({commit, dispatch}, payload) {
            commit('setError', '');
            await axios.post('/product/delete', { id: payload}, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(response => {
                    if (response.data.error) {
                        commit('setError', response.data.error)
                    } else {
                        dispatch('getProducts');
                    }
                })
                .catch(error => {
                    if (error.response) {
                        commit('setError', error.response.data);
                    }
                    throw error
                })
        },
    }
}