import axios from "axios";

export default {
    state: {
        drawer: true,
        error: "",
        tinyMceGlobalOptions: {
            height: 400,
            plugins: ["image", "code", "anchor", "link", "lists", "table", "paste"],
            paste_as_text: false,
            file_picker_types: 'image',
            language: 'ru',
            toolbar: "undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | table | fontsizeselect",
            branding: false,
            /*relative_urls: false,
            remove_script_host: false,
            document_base_url: process.env.VUE_APP_URL_IMAGE,*/
            image_prepend_url: process.env.VUE_APP_URL_IMAGE + "/",
            image_class_list: [
                {"title": 'Адаптив', value: 'img img-fluid'}
            ],
            gecko_spellcheck:true,
            extended_valid_elements : "script[src|async|defer|type|charset]",
            file_picker_callback: function (cb, value, meta) {
                let input = document.createElement('input');

                input.setAttribute('type', 'file');
                input.setAttribute('accept', 'image/*');

                input.onchange = function () {
                    let file = this.files[0];
                    let formData = new FormData();

                    formData.append('file', file, file.name);

                    axios.post('/file', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                        .then(response => {
                            if (!response.data.error) {
                                cb(response.data.location, {title: file.name});
                            } else {
                                //commit('setError', response.data.error)
                                alert('upload error' + value +' '+ meta);
                            }
                        })
                        .catch(error => {
                            if (error.response) {
                                //commit('setError', error.response.data);
                            }
                            throw error
                        })
                    /*reader.onload = function ()
                    {
                        var id = 'blobid' + (new Date()).getTime();
                        var blobCache = tinymce.activeEditor.editorUpload.blobCache;
                        var base64 = reader.result.split(',')[1];
                        var blobInfo = blobCache.create(id, file, base64);
                        blobCache.add(blobInfo);

                        cb(blobInfo.blobUri(), { title: file.name });
                    };

                    reader.readAsDataURL(file);*/
                };

                input.click();
            }
        }
        /*{
            language: 'ru',
            language_url: '/tinyMce/langs/ru.js',
            plugins: [
                'lists link image paste help wordcount'
            ],
            toolbar: 'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | help'
        }*/,
        loadingTable: false
    },
    getters: {
        getDrawer(state) {
            return state.drawer;
        },
        getError(state) {
            return state.error.message;
        },
        getTinyMceGlobalOptions(state) {
            return state.tinyMceGlobalOptions;
        },
        getLoadingTable(state) {
            return state.loadingTable;
        }
    },
    mutations: {
        triggerDrawer(state) {
            state.drawer = !state.drawer;
        },
        setError(state, payload) {
            if (payload != "" && (typeof payload == "string" || payload instanceof String)) {
                payload = {"message": payload}
            }
            state.error = payload;
        },
        setLoadingTable(state, payload) {
            state.loadingTable = payload;
        }
    },
    actions: {
    }
}