import axios from "axios";


export default {
    state: {
        mainBlocks: []
    },
    getters: {
        getMainBlocks(state) {
            return state.mainBlocks;
        }
    },
    mutations: {
        setMainBlocks(state, payload) {
            state.mainBlocks = payload;
        }
    },
    actions: {
        async getMainBlocks({commit}) {
            commit('setError', "");
            commit('setLoadingTable', true);
            await axios.get('/mainBlock')
                .then(response => {
                    commit('setLoadingTable', false);
                    if (!response.data.error) {
                        commit('setMainBlocks', response.data)
                    } else {
                        commit('setError', response.data.error)
                    }
                })
                .catch(error => {
                    commit('setLoadingTable', false);
                    if (error.response) {
                        commit('setError', error.response.data);
                    }
                    throw error
                })
        },
        async saveMainBlock({commit, dispatch}, payload) {
            commit('setError', '');
            await axios.post('/mainBlock/save', payload, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(response => {
                    if (!response.data.error) {
                        dispatch('getMainBlocks');
                    } else {
                        commit('setError', response.data.error)
                    }
                })
                .catch(error => {
                    if (error.response) {
                        commit('setError', error.response.data);
                    }
                    throw error
                })
        },
        async updateOrderId({commit}, payload) {
            commit('setError', '');
            await axios.post('/mainBlock/updateOrder', payload, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(response => {
                    if (response.data.error) {
                        commit('setError', response.data.error)
                    }
                })
                .catch(error => {
                    if (error.response) {
                        commit('setError', error.response.data);
                    }
                    throw error
                })
        },
        async deleteMainBlock({commit, dispatch}, payload) {
            commit('setError', '');
            await axios.post('/mainBlock/delete', { id: payload}, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(response => {
                    if (response.data.error) {
                        commit('setError', response.data.error)
                    } else {
                        dispatch('getMainBlocks');
                    }
                })
                .catch(error => {
                    if (error.response) {
                        commit('setError', error.response.data);
                    }
                    throw error
                })
        },
    }
}