<template>
    <v-col cols="12">
        <text-field-col
                v-model="value.name"
                @input="$emit('input', {...value, name: $event })"
                :counter="100"
                :rules="getRulesTextField(100)"
                label="Название*"
                required="true"
                :cols="12"
        ></text-field-col>

        <text-field-col
                v-model="value.title"
                @input="$emit('input', {...value, title: $event })"
                :counter="100"
                :rules="getRulesTextField(100)"
                label="Заголовок*"
                required="true"
                :cols="12"
        ></text-field-col>

        <v-col cols="12">
            <span>Описание*:</span>
            <tinymce v-model="value.description" @input="$emit('input', {...value, description: $event })" api-key="k52jdx5bppp0np7brnb6b88hpq270a7s6me0czc0p074nlul" :init="getTinyMceOptions" required="true"></tinymce>
        </v-col>

        <v-col cols="12">
            <v-autocomplete
                    v-model="value.parent_id"
                    label="Родитель"
                    @input="$emit('input', {...value, parent_id: $event })"
                    :items="items"
                    item-text="title"
                    item-value="id"
                    outlined
                    deletable-chips
            ></v-autocomplete>
        </v-col>

        <text-field-col
                v-model="value.meta_title"
                @input="$emit('input', {...value, meta_title: $event })"
                :counter="90"
                :rules="getRulesTextField(90)"
                label="Мета заголовок*"
                required="true"
                :cols="12"
        ></text-field-col>

        <text-field-col
                v-model="value.meta_description"
                @input="$emit('input', {...value, meta_description: $event })"
                :counter="150"
                :rules="getRulesTextField(150)"
                label="Мета описание*"
                required="true"
                :cols="12"
        ></text-field-col>

    </v-col>
</template>

<script>
    import TextFieldCol from "@/components/textFieldCol";
    export default {
        name: "PageForm",
        props: {
            value: {
                type: Object,
                default: function () {
                    return {
                        name: '',
                        title: '',
                        description: '',
                        parent_id: null,
                        meta_title: '',
                        meta_description: ''
                    }
                }
            }
        },
        components: {TextFieldCol},

        computed: {
            getTinyMceOptions() {
                return this.$store.getters.getTinyMceGlobalOptions;
            },
            items() {
                return this.$store.getters.getPages;
            }
        },
        methods: {
            getRulesTextField(maxLength, isRequired = true) {
                return [
                    v => !!v && isRequired || 'Поле обязательно',
                    v => v && v.length <= maxLength || `Введено более ${maxLength} символов`
                ]
            }
        },
        beforeCreate() {
            this.$store.dispatch('getPages');
        }
    }
</script>

<style scoped>

</style>