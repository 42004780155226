<template>
    <v-app-bar
            :clipped-left="$vuetify.breakpoint.lgAndUp"
            app
            color="blue darken-4"
            dark
    >
        <v-app-bar-nav-icon @click.stop="triggerDrawer()" />
        <v-toolbar-title
                style="width: 300px"
                class="ml-0 pl-4"
        >
            <router-link to="/" tag="span" style="cursor: pointer;">
                <span class="hidden-sm-and-down">АдминПанель</span>
            </router-link>
        </v-toolbar-title>
        <!--<v-text-field
                flat
                solo-inverted
                hide-details
                prepend-inner-icon="mdi-magnify"
                label="Поиск"
                class="hidden-sm-and-down"
        />-->
        <v-spacer />

        <v-menu
                left
                bottom
                offset-y
                transition="slide-y-transition"
        >
            <template v-slot:activator="{ on }">
                <v-btn icon v-on="on">
                    <v-avatar
                            size="42"
                            color="indigo lighten-1"
                    >
                        {{ getUserAbbreviation }}
                    </v-avatar>
                </v-btn>
            </template>

            <v-list>
                <v-list-item
                        v-for="(item, i) in items"
                        :key="i"
                        :to="item.url"
                >
                    <v-list-item-icon>
                        <v-icon v-text="item.icon"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-menu>
    </v-app-bar>
</template>

<script>

export default {
    name: 'Header',
    data: () => ({
       items: [
           { title: "Выход", url: "logout", icon: "mdi-logout-variant" }
       ]
    }),
    computed: {
        getUserAbbreviation(){
            return this.$store.getters.getUserAbbreviation;
        }
    },
    methods: {
        triggerDrawer() {
            this.$store.commit("triggerDrawer");
        }
    }
}
</script>