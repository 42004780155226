<template>
    <v-container
            fluid
    >
        <v-card>
            <v-card-title>
                <h2>Блоки для главной</h2>
                <v-spacer></v-spacer>
            </v-card-title>
            <v-card-text class="title text--primary col-12">
                <draggable
                        tag="div"
                        :list="mainBlocks"
                        @end="onEnd"
                        handle=".handle">
                    <div
                            v-for="(element) in mainBlocks"
                            :key="element.id"
                    >
                        <i class="mdi mdi-menu handle"></i>
                        <span class="text">{{ element.page.title }} </span>
                        <img style="max-width: 15px" :src="getPathImage+'/'+ element.image" />
                        <i class="mdi mdi-close close" @click="deleteItem(element)"></i>
                    </div>
                    <div class="d-flex mt-9">
                        <div class="col-5">
                            <v-autocomplete
                                    v-model="page"
                                    label="Страница"
                                    :items="getItems"
                                    item-text="title"
                                    return-object
                                    outlined
                                    deletable-chips
                            ></v-autocomplete>
                        </div>
                        <div class="col-6">
                            <v-file-input
                                    small-chips
                                    show-size
                                    outlined
                                    accept="image/png, image/jpeg, image/jpg"
                                    label="Картинка*"
                                    @change="changeFile($event)"
                            ></v-file-input>
                            <div v-if="getUrlImage != ''">
                                <v-img max-width="130px" :src="getUrlImage"></v-img>
                            </div>
                        </div>
                        <div class="col-1">
                            <i class="mdi mdi-plus add col-2 headline" @click="add()"></i>
                        </div>
                    </div>
                </draggable>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
    import draggable from "vuedraggable";

    export default {
        name: "MainBlocks",
        display: "Handle",
        instruction: "Drag using the handle icon",
        components: {
            draggable
        },
        data() {
            return {
                page: {},
                image: "",
                dragging: false
            };
        },
        computed: {
            getUrlSite() {
                return process.env.VUE_APP_URL_SITE;
            },
            getPathImage() {
                return process.env.VUE_APP_URL_IMAGE;
            },
            mainBlocks() {
                return this.$store.getters.getMainBlocks;
            },
            getItems() {
                return this.$store.getters.getPages;
            },
            getLoadingTable() {
                return this.$store.getters.getLoadingTable;
            },
            draggingInfo() {
                return this.dragging ? "under drag" : "";
            },
            getUrlImage() {
                let image = this.image;
                if (image == null || image == "") {
                    return "";
                }
                let result = "";
                if (image instanceof File) {
                    result = URL.createObjectURL(image);
                } else {
                    result = process.env.VUE_APP_URL_IMAGE+'/'+image;
                }

                return result;
            }
        },
        methods: {
            removeAt(idx) {
                this.list.splice(idx, 1);
            },
            changeFile(file) {
                this.image = file;
            },
            onEnd() {
                let payload = [];
                for (let i = 0; i < this.mainBlocks.length; i++) {
                    payload.push({
                        id: this.mainBlocks[i].id,
                        orderId: i
                    });
                }
                this.$store.dispatch("updateOrderId", payload);
            },
            add: function () {
                if (this.page.id) {
                    const countBlock = this.mainBlocks.length + 1;

                    let formData = new FormData();
                    formData.append('image', this.image);
                    formData.append('pageId', this.page.id);
                    formData.append('order_id', countBlock);
                   /* const mainBlock = {
                        pageId: this.page.id,
                        image: this.image,
                        orderId: countBlock
                    };*/
                    this.$store.dispatch('saveMainBlock', formData);
                    //this.list.push({name: this.page.title, id: this.page.id});
                    this.page = {};
                }
            },
            editItem(item) {
                this.$router.push(`/main-block/edit/${item.id}`)
            },
            deleteItem(item) {
                confirm('Удалить этот элемент?') && this.$store.dispatch("deleteMainBlock", item.id)
            }
        },
        mounted() {
            this.$store.dispatch('getPages');
            this.$store.dispatch('getMainBlocks');
        }
    }
</script>

<style scoped>
    .text {
        margin: 0 20px;
    }
</style>