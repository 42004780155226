import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import vuetify from './plugins/vuetify';
import axios from 'axios'
import tinymce from '@tinymce/tinymce-vue';

Vue.component('tinymce', tinymce);
Vue.config.productionTip = false;

axios.defaults.baseURL = process.env.VUE_APP_ROOT_API;
axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.token}`;

axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (error.response.status == 401) {
    store.dispatch("logout");
    throw error;
  }
});

new Vue({
  store,
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app');
