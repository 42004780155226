import axios from "axios";


export default {
    state: {
        links: []
    },
    getters: {
        getLinks(state) {
            return state.links;
        }
    },
    mutations: {
        setLinks(state, payload) {
            state.links = payload;
        }
    },
    actions: {
        async getLinks({commit}) {
            commit('setError', "");
            commit('setLoadingTable', true);
            await axios.get('/links')
                .then(response => {
                    commit('setLoadingTable', false);
                    if (!response.data.error) {
                        commit('setLinks', response.data)
                    } else {
                        commit('setError', response.data.error)
                    }
                })
                .catch(error => {
                    commit('setLoadingTable', false);
                    if (error.response) {
                        commit('setError', error.response.data);
                    }
                    throw error
                })
        },
        async saveLinks({commit, dispatch}, payload) {
            commit('setError', '');
            await axios.post('/links/save', payload, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(response => {
                    if (!response.data.error) {
                        dispatch('getLinks');
                    } else {
                        commit('setError', response.data.error)
                    }
                })
                .catch(error => {
                    if (error.response) {
                        commit('setError', error.response.data);
                    }
                    throw error
                })
        },
        async updateOrderIdLinks({commit}, payload) {
            commit('setError', '');
            await axios.post('/links/updateOrder', payload, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(response => {
                    if (response.data.error) {
                        commit('setError', response.data.error)
                    }
                })
                .catch(error => {
                    if (error.response) {
                        commit('setError', error.response.data);
                    }
                    throw error
                })
        },
        async deleteLinks({commit, dispatch}, payload) {
            commit('setError', '');
            await axios.post('/links/delete', { id: payload}, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(response => {
                    if (response.data.error) {
                        commit('setError', response.data.error)
                    } else {
                        dispatch('getLinks');
                    }
                })
                .catch(error => {
                    if (error.response) {
                        commit('setError', error.response.data);
                    }
                    throw error
                })
        },
    }
}