<template>
    <v-row>
        <v-icon large color="primary" class="float-left my-auto mr-4 ml-2" @click="$router.go(-1)">mdi-keyboard-backspace</v-icon>
        <h1><slot></slot></h1>
    </v-row>

</template>

<script>
    export default {
        name: "TitlePage",
    }
</script>

<style scoped>

</style>