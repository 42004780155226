<template>
  <v-container
          class="fill-height"
          fluid
  >
  </v-container>
</template>

<script>

export default {
  name: 'Home'
}
</script>
