<template>
    <v-container>
        <title-page>Редактировать страницу</title-page>
        <v-form v-model="valid">
            <v-row>
                <page-form v-model="getPage"></page-form>
                <v-col
                    cols="12"
                >
                    <v-btn
                            color="primary"
                            @click="edit()"
                    >
                        Сохранить
                    </v-btn>
                    <v-alert class="mt-4" v-if="getError != null" type="error">{{ getError }}</v-alert>
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>

<script>
    import TitlePage from "@/components/TitlePage";
    import PageForm from "@/components/PageForm";

    export default {
        name: "Edit",
        components: {PageForm, TitlePage},
        data: () => ({
            valid: false,
            page: {}
        }),
        computed: {
            getError() {
                return this.$store.getters.getError;
            },
            getPage: {
                get: function () {
                    return this.$store.getters.getPage;
                },
                set: function (newValue) {
                    this.page = newValue;
                }
            }
        },
        methods: {
            edit() {
                if (!this.page.id) {
                    this.$store.commit('setError', "Нет изменений для сохранения");
                    return ;
                }
                let formData = new FormData();
                formData.append('name', this.page.name);
                formData.append('title', this.page.title);
                formData.append('description', this.page.description);
                formData.append("id", this.page.id);

                if (this.page.parent_id !== undefined) {
                    formData.append('parent_id',  this.page.parent_id);
                }

                formData.append('metaTitle', this.page.meta_title);
                formData.append('metaDescription', this.page.meta_description);

                this.$store.dispatch('editPage', formData);
                return this.page;
            }
        },
        beforeCreate() {
            this.$store.dispatch('getPage', this.$route.params.id);
        }
    }
</script>

<style scoped>

</style>