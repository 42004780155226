<template>
    <div>
        <div class="row">
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="addTitle()">Добавить заголовок</v-btn>
        </div>
        <div
                v-for="(specification, index) in value"
                :key="index">
            <div class="row">
                <div class="col-6">
                    <text-field-col
                            v-model="specification.title"
                            :counter="90"
                            :rules="getRulesTextField(90)"
                            @input="change()"
                            label="Заголовок*"
                            required="true"
                            :cols="12"
                    ></text-field-col>
                </div>
                <div class="col-2">
                    <v-btn color="red darken-4" dark class="mt-5" @click="deleteItem(value, index)">Удалить</v-btn>
                </div>
            </div>
            <div class="col-4">
                <v-btn color="primary" @click="addChildren(specification.children)">Добавить подзаголовок</v-btn>
            </div>
            <div class="offset-1 row" v-for="(children, indexCh) in specification.children" :key="indexCh">
                <div class="col-5">
                    <text-field-col
                            v-model="children.key"
                            :counter="90"
                            :rules="getRulesTextField(90)"
                            @input="change()"
                            label="Ключ*"
                            required="true"
                            :cols="12"
                    ></text-field-col>
                </div>
                <div class="col-5">
                    <text-field-col
                            v-model="children.value"
                            :counter="90"
                            :rules="getRulesTextField(90)"
                            @input="change()"
                            label="Значение*"
                            required="true"
                            :cols="12"
                    ></text-field-col>
                </div>
                <div class="col-2">
                    <v-btn color="red darken-4" dark class="mt-5" @click="deleteItem(specification.children, indexCh)">Удалить</v-btn>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import TextFieldCol from "@/components/textFieldCol";

    export default {
        name: "Specifications",
        components: {TextFieldCol},
        props: {
            value: {
                type: Array,
                default: () => [
                    {
                        title: '',
                        children: [
                            {
                                key: '',
                                value: ''
                            }
                        ]
                    }
                ]
            }
        },
        data: () => ({
            countTitle: 1
        }),
        methods: {
            change() {
                return this.$emit('input', this.value)
            },
            getRulesTextField(maxLength, isRequired = true) {
                return [
                    v => !!v && isRequired || 'Поле обязательно',
                    v => v && v.length <= maxLength || `Введено более ${maxLength} символов`
                ]
            },
            addTitle() {
                this.value.push({
                    title: '',
                    children: [
                        {
                            key: '',
                            value: ''
                        }
                    ]
                });
            },
            addChildren(item) {
                item.push({
                    key: '',
                    value: ''
                })
            },
            deleteItem(value,item) {
                value = value.splice(item, 1);
            }
        }
    }
</script>

<style scoped>

</style>