<template>
    <v-container>
        <title-page>Создать страницу</title-page>
        <v-form v-model="valid">
            <v-row>
                <page-form v-model="page"></page-form>
                <v-col
                    cols="12"
                >
                    <v-btn
                            color="primary"
                            @click="save()"
                    >
                        Сохранить
                    </v-btn>
                    <v-alert class="mt-4" v-if="getError != null" type="error">{{ getError }}</v-alert>
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>

<script>
    import TitlePage from "@/components/TitlePage";
    import PageForm from "@/components/PageForm";

    export default {
        name: "Create",
        components: {PageForm, TitlePage},
        data: () => ({
            valid: false,
            page: {
                name: '',
                title: '',
                description: '',
                parent_id: null,
                meta_title: '',
                meta_description: ''
            }
        }),
        computed: {
            getError() {
                return this.$store.getters.getError;
            }
        },
        methods: {
            save() {
                if (!this.validate()) {
                    this.$store.commit('setError', "Заполните все обязательные поля");
                    return;
                }
                let formData = new FormData();

                formData.append('name', this.page.name);
                formData.append('description', this.page.description);

                formData.append('metaTitle', this.page.meta_title);
                formData.append('metaDescription', this.page.meta_description);
                formData.append('title', this.page.title);
                formData.append('parent_id', this.page.parent_id);

                this.$store.dispatch('savePage', formData);
                return this.page;
            },
            validate() {
                return this.valid
                    && (this.page.description != "" && this.page.description != null)
                    && (this.page.name != "" && this.page.name != null)
            }
        },
        beforeCreate() {
            this.$store.dispatch('getPages');
        }
    }
</script>

<style scoped>

</style>