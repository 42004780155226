import axios from "axios";
import router from "@/router";


export default {
    state: {
        pages: [],
        page: {}
    },
    getters: {
        getPages(state) {
            return state.pages;
        },
        getPage(state) {
            return state.page;
        }
    },
    mutations: {
        setPages(state, payload) {
            state.pages = payload;
        },
        setPage(state, payload) {
            state.page = payload;
        }
    },
    actions: {
        async getPages({commit}) {
            commit('setError', "");
            commit('setLoadingTable', true);
            await axios.get('/page/get')
                .then(response => {
                    commit('setLoadingTable', false);
                    if (!response.data.error) {
                        commit('setPages', response.data)
                    } else {
                        commit('setError', response.data.error)
                    }
                })
                .catch(error => {
                    commit('setLoadingTable', false);
                    if (error.response) {
                        commit('setError', error.response.data);
                    }
                    throw error
                })
        },
        async getPage({commit}, pageId) {
            commit('setError', "");
            await axios.get('/page/get/' + pageId)
                .then(response => {
                    if (!response.data.error) {
                        commit('setPage', response.data)
                    } else {
                        commit('setError', response.data.error)
                    }
                })
                .catch(error => {
                    if (error.response) {
                        commit('setError', error.response.data);
                    }
                    throw error
                })
        },
        async savePage({commit}, payload) {
            commit('setError', '');
            await axios.post('/page/save', payload, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(response => {
                    if (!response.data.error) {
                        router.push('/page');
                    } else {
                        commit('setError', response.data.error)
                    }
                })
                .catch(error => {
                    if (error.response) {
                        commit('setError', error.response.data);
                    }
                    throw error
                })
        },
        async editPage({commit}, payload) {
            commit('setError', '');
            await axios.post('/page/edit', payload, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(response => {
                    if (!response.data.error) {
                        router.push('/page');
                    } else {
                        commit('setError', response.data.error)
                    }
                })
                .catch(error => {
                    if (error.response) {
                        commit('setError', error.response.data);
                    }
                    throw error
                })
        },
        async deletePage({commit, dispatch}, payload) {
            commit('setError', '');
            await axios.post('/page/delete', { id: payload}, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(response => {
                    if (response.data.error) {
                        commit('setError', response.data.error)
                    } else {
                        dispatch('getPages');
                    }
                })
                .catch(error => {
                    if (error.response) {
                        commit('setError', error.response.data);
                    }
                    throw error
                })
        },
    }
}