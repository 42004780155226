<template>
    <v-container
            fluid
    >
        <v-card>
            <v-card-title>
                <h2>Страницы</h2>
                <v-spacer></v-spacer>
                <v-text-field
                        v-model="search"
                        append-icon="mdi-search"
                        label="Поиск"
                        single-line
                        hide-details
                ></v-text-field>
                <v-btn :to="{name: 'pageCreate'}" color="primary" dark class="ml-4">Создать</v-btn>
            </v-card-title>
            <v-data-table
                    :headers="headers"
                    :items="getItems"
                    :search="search"
                    :loading="getLoadingTable"
            >
                <template v-slot:item.url="{item}">
                    <a :href="getUrlSite+item.url" target="_blank">{{item.url}}</a>
                </template>
                <template v-slot:item.action="{ item }">
                    <v-icon
                            class="mr-2"
                            @click="editItem(item)"
                    >
                        mdi-pencil
                    </v-icon>
                    <v-icon
                            @click="deleteItem(item)"
                    >
                        mdi-delete
                    </v-icon>
                </template>
            </v-data-table>
        </v-card>
    </v-container>
</template>

<script>
    export default {
        name: "Pages",
        data () {
            return {
                search: '',
                headers: [
                    {
                        text: 'Название',
                        align: 'left',
                        sortable: false,
                        value: 'name',
                    },
                    { text: 'Ссылка', value: 'url' },
                    { text: 'Мета заголовок', value: 'meta_title' },
                    { text: 'Мета описание', value: 'meta_description' },
                    { text: 'Действие', sortable:false, value: 'action' },
                ]
            }
        },
        computed: {
            getUrlSite() {
                return process.env.VUE_APP_URL_SITE.slice(0, -1);
            },
            getItems() {
                return this.$store.getters.getPages;
            },
            getLoadingTable() {
                return this.$store.getters.getLoadingTable;
            }
        },
        methods: {
            editItem (item) {
                this.$router.push(`/page/edit/${item.id}`)
            },
            deleteItem (item) {
                confirm('Удалить этот элемент?') && this.$store.dispatch("deletePage", item.id)
            }
        },
        mounted() {
            this.$store.dispatch('getPages');
        }
    }
</script>

<style scoped>

</style>