import Vue from 'vue'
import Vuex from 'vuex'
import general from "@/store/modules/general";
import user from "@/store/modules/user";
import category from "@/store/modules/category";
import product from "@/store/modules/product";
import page from "@/store/modules/page";
import mainBlock from "@/store/modules/mainBlock";
import linksHeader from "@/store/modules/linksHeader";
import settings from "@/store/modules/settings";

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    general,
    user,
    category,
    product,
    page,
    mainBlock,
    linksHeader,
    settings
  }
})
