<template>
    <v-col
            cols="12"
    >
        <v-text-field
                v-model="valueInput"
                :rules="rules"
                :counter="counter"
                outlined
                :label="label"
                :type="type"
                :required="isRequired"
                @input="$emit('input', $event)"
        ></v-text-field>
    </v-col>
</template>

<script>
    export default {
        name: "textFieldCol",
        props: {
            value: [String, Number],
            rules: Array,
            counter: Number,
            label: String,
            isRequired: Boolean,
            cols: Number,
            type: {
                type: String,
                default: function () {
                    return "text";
                }
            }
        },
        computed: {
            valueInput: {
                get: function() {
                    return this.value;
                },
                set: function (newValue) {
                    this.$emit('input', newValue)
                }
            }
        }
    }
</script>

<style scoped>

</style>