import axios from 'axios'
import router from "@/router";

export default {
    state: {
        token: null,
        user: null
    },
    getters: {
        getToken(state) {
            return state.token;
        },
        getUserAbbreviation(state) {
          return state.user ? state.user.abbreviation : "AL";
        },
        getUser(state) {
            return state.user
        }
    },
    mutations: {
        setToken(state, payload) {
            state.token = payload;
        },
        setUser(state, payload) {
            state.user = payload;
        }
    },
    actions: {
        async authenticate ({commit}, payload) {
            commit('setError', "");

            await axios.post('/authenticate', payload)
            .then(response => {
                if (response.data.token) {
                    commit('setUser', response.data.user)
                    localStorage.token = response.data.token
                    axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.token}`;
                } else {
                    commit('setError', response.data)
                }
            })
            .catch(error => {
                if (error.response) {
                    commit('setError', error.response.data)
                }
                throw error
            })
        },
        logout ({commit}) {
            commit('setError', "");
            commit('setUser', '');

            localStorage.clear();
            router.push("/authorization");
        }
    }
}