<template>
  <v-app id="inspire">
    <NavigatorDrawer v-if="!$route.meta.ui"></NavigatorDrawer>
    <Header v-if="!$route.meta.ui"></Header>
    <v-content>
      <transition v-if="!$route.meta.ui" appear name="slide-fade" mode="out-in" duration="500">
        <router-view></router-view>
      </transition>
      <router-view v-else></router-view>
    </v-content>
    <!--   <v-btn
              bottom
              color="pink"
              dark
              fab
              fixed
              right
              @click="dialog = !dialog"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
     <v-dialog
              v-model="dialog"
              width="800px"
      >
        <v-card>
          <v-card-title class="grey darken-2">
            Create contact
          </v-card-title>
          <v-container>
            <v-row class="mx-2">
              <v-col
                      class="align-center justify-space-between"
                      cols="12"
              >
                <v-row
                        align="center"
                        class="mr-0"
                >
                  <v-avatar
                          size="40px"
                          class="mx-3"
                  >
                    <img
                            src="//ssl.gstatic.com/s2/oz/images/sge/grey_silhouette.png"
                            alt=""
                    >
                  </v-avatar>
                  <v-text-field
                          placeholder="Name"
                  />
                </v-row>
              </v-col>
              <v-col cols="6">
                <v-text-field
                        prepend-icon="business"
                        placeholder="Company"
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                        placeholder="Job title"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                        prepend-icon="mail"
                        placeholder="Email"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                        type="tel"
                        prepend-icon="phone"
                        placeholder="(000) 000 - 0000"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                        prepend-icon="notes"
                        placeholder="Notes"
                />
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions>
            <v-btn
                    text
                    color="primary"
            >More</v-btn>
            <v-spacer />
            <v-btn
                    text
                    color="primary"
                    @click="dialog = false"
            >Cancel</v-btn>
            <v-btn
                    text
                    @click="dialog = false"
            >Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>-->
  </v-app>
</template>

<script>
import Header from "./views/layouts/Header";
import NavigatorDrawer from "@/views/layouts/NavigatorDrawer";

export default {
  components: {
    NavigatorDrawer,
    Header
  },
  data: () => ({
    dialog: false,
  })
};
</script>
<style>
  .slide-fade-enter-active {
    transition: all .5s ease;
  }
  .slide-fade-leave-active {
    transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .slide-fade-enter, .slide-fade-leave-to {
    transform: translateX(50px);
    opacity: 0;
  }
</style>
