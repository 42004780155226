<template>
    <v-navigation-drawer
            :value="getDrawer"
            :clipped="$vuetify.breakpoint.lgAndUp"
            app
    >
        <v-list dense>
            <template v-for="item in items">
                <v-list-group
                        v-if="item.children"
                        :key="item.text"
                        v-model="item.model"
                        :prepend-icon="item.model ? item.icon : item['icon-alt']"
                        append-icon=""
                >
                    <template v-slot:activator>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ item.text }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                    <v-list-item
                            v-for="(child, i) in item.children"
                            :key="i"
                            link
                    >
                        <v-list-item-action v-if="child.icon">
                            <v-icon>{{ child.icon }}</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ child.text }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-group>
                <v-list-item
                        v-else
                        :key="item.text"
                        link
                        :to="item.link"
                >
                    <v-list-item-action>
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ item.text }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
    export default {
        name: "NavigatorDrawer",
        data() {
            return {
                drawer: null,
                items: [
                    { icon: 'mdi-book-multiple', text: 'Категории', link: '/category' },
                    { icon: 'mdi-history', text: 'Товары', link: '/products' },
                    { icon: 'mdi-content-copy', text: 'Страницы', link: '/page' },
                    { icon: 'mdi-format-align-justify', text: 'Блок на главной', link: '/main-blocks' },
                    { icon: 'mdi-format-align-justify', text: 'Ссылки для хедера', link: '/links' },
                    /*{
                        icon: 'mdi-chevron-up',
                        'icon-alt': 'mdi-chevron-down',
                        text: 'Labels',
                        model: false,
                        children: [
                            { icon: 'mdi-plus', text: 'Create label' },
                        ],
                    },
                    {
                        icon: 'mdi-chevron-up',
                        'icon-alt': 'mdi-chevron-down',
                        text: 'More',
                        model: false,
                        children: [
                            { text: 'Import' },
                            { text: 'Export' },
                            { text: 'Print' },
                            { text: 'Undo changes' },
                            { text: 'Other contacts' },
                        ],
                    },*/
                    { icon: 'mdi-settings', text: 'Настройки', link: '/settings' }
                ]
            }
        },
        computed: {
            getDrawer() {
                return this.$store.getters.getDrawer
            }
        }
    }
</script>

<style scoped>

</style>