<template>
    <v-app id="inspire">
        <v-content>
            <v-container fluid fill-height>
                <v-layout align-center justify-center>
                    <v-flex xs12 sm8 md4>
                        <v-card class="elevation-12">
                            <v-toolbar dark color="primary">
                                <v-toolbar-title>Авторизация</v-toolbar-title>
                                <v-spacer></v-spacer>
                            </v-toolbar>
                            <v-card-text>
                                <v-form>
                                    <v-text-field prepend-icon="mdi-account" name="login" label="Логин"
                                                  type="text" v-model="login"></v-text-field>
                                    <v-text-field id="password" prepend-icon="mdi-lock" name="password" label="Пароль"
                                                  type="password" v-model="password"></v-text-field>
                                </v-form>
                            </v-card-text>
                            <v-card-actions>
                                <span style="color:red;">{{getError}}</span>
                                <v-spacer></v-spacer>
                                <v-btn color="primary" @click="auth()">Авторизоваться</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-content>
    </v-app>
</template>

<script>
    export default {
        name: "Authorization",
        data() {
            return {
                login: "",
                password: ""
            }
        },
        computed: {
            getError() {
                return this.$store.getters.getError;
            }
        },
        methods: {
            auth: function() {
                const user = {
                    login: this.login,
                    password: this.password
                };
                this.$store.dispatch('authenticate', user)
                    .then(() => {
                        this.$router.push('/')
                    })
                    .catch(error => { this.$store.commit('setError', error) })
            }
        }
    }
</script>

<style scoped>

</style>