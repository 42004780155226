<template>
    <v-container>
        <title-page>Редактировать категорию</title-page>
        <v-form v-model="valid">
            <v-row>
                <category-form v-model="getCategoryInfo"></category-form>
                <v-col
                    cols="12"
                >
                    <v-btn
                            color="primary"
                            @click="edit()"
                    >
                        Сохранить
                    </v-btn>
                    <v-alert class="mt-4" v-if="getError != null" type="error">{{ getError }}</v-alert>
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>

<script>
    import TitlePage from "@/components/TitlePage";
    import CategoryForm from "@/components/CategoryForm";

    export default {
        name: "Edit",
        components: {CategoryForm, TitlePage},
        data: () => ({
            valid: false,
            category: {}
        }),
        computed: {
            getError() {
                return this.$store.getters.getError;
            },
            getCategoryInfo: {
                get: function () {
                    return this.$store.getters.getCategoryInfo;
                },
                set: function (newValue) {
                    this.category = newValue;
                }
            }
        },
        methods: {
            edit() {
                if (!this.category.id) {
                    this.$store.commit('setError', "Нет изменений для сохранения");
                    return ;
                }
                let formData = new FormData();
                formData.append('title', this.category.title);
                formData.append('description', this.category.description);
                formData.append("id", this.category.id);

                if (this.category.parent_id !== undefined) {
                    formData.append('parent_id',  this.category.parent_id);
                }

                formData.append('metaTitle', this.category.meta_title);
                formData.append('metaDescription', this.category.meta_description);
                for (let i = 0; i < this.category.image.length; i++) {
                    formData.append('image[]', this.category.image[i]);
                }
                formData.append('order_id', this.category.order_id);
              formData.append('order_product', this.category.order_product);

                this.$store.dispatch('editCategory', formData);
                return this.category;
            }
        },
        beforeCreate() {
            this.$store.dispatch('getItemsSubcategory');
            this.$store.dispatch('getCategoryInfo', this.$route.params.id);
        }
    }
</script>

<style scoped>

</style>