<template>
    <div></div>
</template>

<script>
    export default {
        name: "Logout",
        beforeCreate() {
            this.$store.dispatch("logout")
                .then(() => {
                    this.$router.push('/authorization');
                })
        }
    }
</script>

<style scoped>

</style>