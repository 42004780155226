<template>
    <v-container
            fluid
    >
        <v-card>
            <v-card-title>
                <h2>Ссылки в хедере</h2>
                <v-spacer></v-spacer>
            </v-card-title>
            <v-card-text class="title text--primary col-6">
                <draggable
                        tag="div"
                        :list="links"
                        @end="onEnd"
                        handle=".handle">
                    <div
                            v-for="(element) in links"
                            :key="element.id"
                    >
                        <i class="mdi mdi-menu handle"></i>
                        <span class="text">{{ element.page.title }} </span>

                        <i class="mdi mdi-close close" @click="deleteItem(element)"></i>
                    </div>
                    <div class="d-flex mt-9">
                        <v-autocomplete
                                v-model="page"
                                label="Страница"
                                :items="getItems"
                                item-text="title"
                                return-object
                                outlined
                                deletable-chips
                        ></v-autocomplete>
                        <i class="mdi mdi-plus add col-2 headline" @click="add()"></i>
                    </div>
                </draggable>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
    import draggable from "vuedraggable";

    export default {
        name: "Links",
        display: "Handle",
        instruction: "Drag using the handle icon",
        components: {
            draggable
        },
        data() {
            return {
                page: {},
                dragging: false
            };
        },
        computed: {
            getUrlSite() {
                return process.env.VUE_APP_URL_SITE;
            },
            links() {
                return this.$store.getters.getLinks;
            },
            getItems() {
                return this.$store.getters.getPages;
            },
            getLoadingTable() {
                return this.$store.getters.getLoadingTable;
            },
            draggingInfo() {
                return this.dragging ? "under drag" : "";
            }
        },
        methods: {
            onEnd() {

                let payload = [];
                for (let i = 0; i < this.links.length; i++) {
                    payload.push({
                        id: this.links[i].id,
                        orderId: i
                    });
                }
                this.$store.dispatch("updateOrderIdLinks", payload);
            },
            add: function () {
                if (this.page.id) {
                    const countBlock = this.links.length + 1;
                    const mainBlock = {
                        pageId: this.page.id,
                        orderId: countBlock
                    };
                    this.$store.dispatch('saveLinks', mainBlock);
                    //this.list.push({name: this.page.title, id: this.page.id});
                    this.page = {};
                }
            },
            editItem(item) {
                this.$router.push(`/links/edit/${item.id}`)
            },
            deleteItem(item) {
                confirm('Удалить этот элемент?') && this.$store.dispatch("deleteLinks", item.id)
            }
        },
        mounted() {
            this.$store.dispatch('getPages');
            this.$store.dispatch('getLinks');
        }
    }
</script>

<style scoped>
    .text {
        margin: 0 20px;
    }
</style>