<template>
    <v-col cols="12">
        <text-field-col
                v-model="value.name"
                @input="$emit('input', {...value, name: $event })"
                :counter="100"
                :rules="getRulesTextField(100)"
                label="Заголовок*"
                required="true"
                :cols="12"
        ></text-field-col>

        <v-col cols="12">
            <span>Описание*:</span>
            <tinymce v-model="value.description" @input="$emit('input', {...value, description: $event })" api-key="k52jdx5bppp0np7brnb6b88hpq270a7s6me0czc0p074nlul" :init="getTinyMceOptions" required="true"></tinymce>
        </v-col>

        <v-col cols="12">
            <v-file-input
                    small-chips
                    show-size
                    outlined
                    multiple
                    accept="image/png, image/jpeg, image/jpg"
                    label="Картинка товара*"
                    @change="changeFile($event)"
                    @input="$emit('input', {...value, image: $event })"
            ></v-file-input>
            <draggable
                    tag="div"
                    class="d-flex"
                    v-if="getUrlImages != ''"
                    :list="getImagesDrag"
            >
                    <v-img
                            max-width="130px"
                            max-height="100px"
                            :src="getUrl"
                            v-for="(getUrl,index) in getUrlImages"
                            :key="index"
                    ></v-img>
            </draggable>

        </v-col>

        <text-field-col
                v-model="value.price"
                @input="$emit('input', {...value, price: $event })"
                :counter="20"
                :rules="getRulesTextField(20)"
                label="Цена*"
                required="true"
                :cols="12"
                type="number"
        ></text-field-col>

        <v-col cols="12">
            <v-autocomplete
                    v-model="value.category_id"
                    label="Категория"
                    @input="$emit('input', {...value, category_id: $event })"
                    :items="items"
                    item-text="title"
                    item-value="id"
                    outlined
                    deletable-chips
            ></v-autocomplete>
        </v-col>

        <text-field-col
                v-model="value.meta_title"
                @input="$emit('input', {...value, meta_title: $event })"
                :counter="90"
                :rules="getRulesTextField(90)"
                label="Мета заголовок*"
                required="true"
                :cols="12"
        ></text-field-col>

        <text-field-col
                v-model="value.meta_description"
                @input="$emit('input', {...value, meta_description: $event })"
                :counter="150"
                :rules="getRulesTextField(150)"
                label="Мета описание*"
                required="true"
                :cols="12"
        ></text-field-col>
        <text-field-col
                v-model="value.order_id"
                @input="$emit('input', {...value, order_id: $event })"
                type="number"
                label="Сортировка"
                required="true"
                :cols="12"
        ></text-field-col>
        <Specifications
                v-model="value.specifications"
                @input="$emit('input', {...value, specifications: $event })"
        ></Specifications>
    </v-col>
</template>

<script>
    import TextFieldCol from "@/components/textFieldCol";
    import Specifications from "@/components/product/Specifications";
    import draggable from "vuedraggable";
    export default {
        name: "ProductForm",
        props: {
            value: {
                type: Object,
                default: function () {
                    return {
                        name: '',
                        image: '',
                        description: '',
                        price: 0,
                        category_id: null,
                        specifications: [
                            {
                                title: '',
                                children: [
                                    {
                                        key: '',
                                        value: ''
                                    }
                                ]
                            }
                        ],
                        meta_title: '',
                        meta_description: '',
                        order_id: 200
                    }
                }
            }
        },
        data() {
            return {
                images: []
            };
        },
        components: {Specifications, TextFieldCol, draggable},

        computed: {
            getTinyMceOptions() {
                return this.$store.getters.getTinyMceGlobalOptions;
            },
            items() {
                return this.$store.getters.getCategories;
            },
            getImagesDrag() {
                return this.value.image;
            },
            getUrlImages() {
                let image = this.value.image;
                if (image == null || image == "") {
                    return "";
                }
                let result = [];
                if (image instanceof Object) {
                    for(let i = 0; i < image.length; i++) {
                        if (image[i] instanceof File) {
                            result.push(URL.createObjectURL(image[i]));
                        } else {
                            result.push(process.env.VUE_APP_URL_IMAGE+'/'+image[i]);
                        }
                    }
                }
                else if (image instanceof File) {
                    result.push(URL.createObjectURL(image));
                } else {
                    let img = image.split("#%@");
                    for(let i = 0; i < img.length; i++) {
                            result.push(process.env.VUE_APP_URL_IMAGE+'/'+img[i]);
                    }
                }

                return result;
            }
        },
        methods: {
            getRulesTextField(maxLength, isRequired = true) {
                return [
                    v => !!v && isRequired || 'Поле обязательно',
                    v => v && v.length <= maxLength || `Введено более ${maxLength} символов`
                ]
            },
            changeFile(file) {
                this.value.image = file;
                this.$emit('input', {...this.value, image: file })
            }
        },
        beforeCreate() {
            this.$store.dispatch('getCategories');
        }
    }
</script>

<style scoped>

</style>