import axios from "axios";

export default {
    state: {
        settings: {},
        loading: false,
        message: null
    },
    getters: {
        getSettings(state) {
            return state.settings;
        },
        getMessage(state) {
            return state.message;
        },
    },
    mutations: {
        setSettings(state, payload) {
            state.settings = payload;
        },
        setMessage(state, payload) {
            state.message = payload;
        }
    },
    actions: {
        async getSettings({commit}) {
            commit('setError', "");
            await axios.get('/settings')
                .then(response => {
                    if (!response.data.error) {
                        commit('setSettings', response.data)
                    } else {
                        commit('setError', response.data.error)
                    }
                })
                .catch(error => {
                    if (error.response) {
                        commit('setError', error.response.data);
                    }
                    throw error
                })
        },
        async saveSettings({commit}, payload) {
            commit('setError', '');
            await axios.post('/settings/save', payload, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(response => {
                    if (!response.data.error) {
                        commit('setMessage', "Успешно");
                    } else {
                        commit('setError', response.data.error)
                    }
                })
                .catch(error => {
                    if (error.response) {
                        commit('setError', error.response.data);
                    }
                    throw error
                })
        }
    }
}