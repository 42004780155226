import axios from "axios";
import router from "@/router";

export default {
    state: {
        itemsSubcategory: [],
        categories: [],
        categoryInfo: {},
        loading: false
    },
    getters: {
        getItemsSubcategory(state) {
            return state.itemsSubcategory;
        },
        getCategories(state) {
            return state.categories;
        },
        getCategoryInfo(state) {
            return state.categoryInfo;
        }
    },
    mutations: {
        setItemsSubcategory(state, payload) {
            state.itemsSubcategory = payload;
        },
        setCategories(state, payload) {
            state.categories = payload;
        },
        setCategoryInfo(state, payload) {
            state.categoryInfo = payload;
        }
    },
    actions: {
        async getItemsSubcategory({commit}) {
            commit('setError', "");
            await axios.get('/category/getSubCategory')
                .then(response => {
                    if (!response.data.error) {
                        commit('setItemsSubcategory', response.data)
                    } else {
                        commit('setError', response.data.error)
                    }
                })
                .catch(error => {
                    if (error.response) {
                        commit('setError', error.response.data);
                    }
                    throw error
                })
        },
        async getCategories({commit}) {
            commit('setError', "");
            commit('setLoadingTable', true);
            await axios.get('/category/getCategories')
                .then(response => {
                    commit('setLoadingTable', false);
                    if (!response.data.error) {
                        commit('setCategories', response.data)
                    } else {
                        commit('setError', response.data.error)
                    }
                })
                .catch(error => {
                    commit('setLoadingTable', false);
                    if (error.response) {
                        commit('setError', error.response.data);
                    }
                    throw error
                })
        },
        async getCategoryInfo({commit}, categoryId) {
            commit('setError', "");
            await axios.get('/category/getCategoryInfo', { params: { categoryId } })
                .then(response => {
                    if (!response.data.error) {
                        commit('setCategoryInfo', response.data)
                    } else {
                        commit('setError', response.data.error)
                    }
                })
                .catch(error => {
                    if (error.response) {
                        commit('setError', error.response.data);
                    }
                    throw error
                })
        },
        async saveCategory({commit}, payload) {
            commit('setError', '');
            await axios.post('/category/save', payload, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(response => {
                    if (!response.data.error) {
                        router.push('/category');
                    } else {
                        commit('setError', response.data.error)
                    }
                })
                .catch(error => {
                    if (error.response) {
                        commit('setError', error.response.data);
                    }
                    throw error
                })
        },
        async editCategory({commit}, payload) {
            commit('setError', '');
            await axios.post('/category/edit', payload, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(response => {
                    if (!response.data.error) {
                        router.push('/category');
                    } else {
                        commit('setError', response.data.error)
                    }
                })
                .catch(error => {
                    if (error.response) {
                        commit('setError', error.response.data);
                    }
                    throw error
                })
        },
        async deleteCategory({commit, dispatch}, payload) {
            commit('setError', '');
            await axios.post('/category/delete', { id: payload}, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(response => {
                    if (response.data.error) {
                        commit('setError', response.data.error)
                    } else {
                        dispatch('getCategories');
                    }
                })
                .catch(error => {
                    if (error.response) {
                        commit('setError', error.response.data);
                    }
                    throw error
                })
        },
    }
}