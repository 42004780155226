import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Category from "@/views/Category";
import Authorization from "@/views/Authorization";
import Logout from "@/views/Logout";
import Create from "@/views/category/Create";
import Edit from "@/views/category/Edit";
import Products from "@/views/Products";
import ProductCreate from  "@/views/product/Create";
import ProductEdit from "@/views/product/Edit";
import Pages from "@/views/Pages";
import PagesCreate from "@/views/pages/PagesCreate";
import PagesEdit from "@/views/pages/PagesEdit";
import MainBlocks from "@/views/MainBlocks";
import Links from "@/views/Links";
import Settings from "@/views/Settings";

Vue.use(VueRouter)


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: "/authorization",
    name: "Authorization",
    component: Authorization,
    meta: { ui: true }
  },
  {
    path: "/logout",
    name: "Logout",
    component: Logout,
    meta: { ui: true }
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: "/category",
    name: "Category",
    component: Category
  },
  {
    path:'/category/create',
    name:'categoryCreate',
    component: Create
  },
  {
    path:'/category/edit/:id',
    name:'categoryEdit',
    component: Edit
  },
  {
    path: "/products",
    name: "Products",
    component: Products
  },
  {
    path: "/products/create",
    name: "productCreate",
    component: ProductCreate
  },
  {
    path:'/products/edit/:id',
    name:'productEdit',
    component: ProductEdit
  },
  {
    path: "/page",
    name: "page",
    component: Pages
  },
  {
    path: "/page/create",
    name: "pageCreate",
    component: PagesCreate
  },
  {
    path:'/page/edit/:id',
    name:'pageEdit',
    component: PagesEdit
  },
  {
    path:'/main-blocks',
    name:'mainBlocks',
    component: MainBlocks
  },
  {
    path:'/links',
    name:'links',
    component: Links
  },
  {
    path:'/settings',
    name:'settings',
    component: Settings
  },
];

const router = new VueRouter({
  mode: 'history',
  routes
});

router.beforeEach((to, from, next) => {
  if (localStorage.token) {
    next();
  } else if (to.name == "Authorization"){
    next();
  } else {
    next('/authorization');
  }
});
export default router
