<template>
    <v-container>
        <title-page>Редактировать товар</title-page>
        <v-form v-model="valid">
            <v-row>
                <product-form v-model="getProduct"></product-form>
                <v-col
                        cols="12"
                >
                    <v-btn
                            color="primary"
                            @click="edit()"
                    >
                        Сохранить
                    </v-btn>
                    <v-alert class="mt-4" v-if="getError != null" type="error">{{ getError }}</v-alert>
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>

<script>
    import TitlePage from "@/components/TitlePage";
    import ProductForm from "@/components/ProductForm";

    export default {
        name: "Edit",
        components: {ProductForm, TitlePage},
        data: () => ({
            valid: false,
            product: {}
        }),
        computed: {
            getError() {
                return this.$store.getters.getError;
            },
            getProduct: {
                get: function () {
                    return this.$store.getters.getProduct;
                },
                set: function (newValue) {
                    this.product = newValue;
                }
            }
        },
        methods: {
            edit() {
                if (!this.product.id) {
                    this.$store.commit('setError', "Нет изменений для сохранения");
                    return;
                }
                let formData = new FormData();
                formData.append('name', this.product.name);
                formData.append('description', this.product.description);
                formData.append('price', this.product.price);
                formData.append('category_id', this.product.category_id);
                formData.append("id", this.product.id);

                formData.append('metaTitle', this.product.meta_title);
                formData.append('metaDescription', this.product.meta_description);
                formData.append('specifications', JSON.stringify(this.product.specifications));
                for (let i = 0; i < this.product.image.length; i++) {
                    formData.append('image[]', this.product.image[i]);
                }

                formData.append('order_id', this.product.order_id);

                this.$store.dispatch('editProduct', formData);
                return this.product;
            }
        },
        beforeCreate() {
            this.$store.dispatch('getProduct', this.$route.params.id);
        }
    }
</script>

<style scoped>

</style>