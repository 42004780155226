<template>
    <v-container>
        <title-page>Создать категорию</title-page>
        <v-form v-model="valid">
            <v-row>
                <category-form v-model="category"></category-form>
                <v-col
                    cols="12"
                >
                    <v-btn
                            color="primary"
                            @click="save()"
                    >
                        Сохранить
                    </v-btn>
                    <v-alert class="mt-4" v-if="getError != null" type="error">{{ getError }}</v-alert>
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>

<script>
    import TitlePage from "@/components/TitlePage";
    import CategoryForm from "@/components/CategoryForm";

    export default {
        name: "Create",
        components: {CategoryForm, TitlePage},
        data: () => ({
            valid: false,
            category: {
                title: '',
                description: '',
                parent_id: null,
                image: '',
                meta_title: '',
                meta_description: '',
                order_id: 200,
                order_product: 0,
            }
        }),
        computed: {
            getError() {
                return this.$store.getters.getError;
            }
        },
        methods: {
            save() {
                if (!this.validate()) {
                    this.$store.commit('setError', "Заполните все обязательные поля");
                    return;
                }
                let formData = new FormData();
                formData.append('title', this.category.title);
                formData.append('description', this.category.description);

                if (this.category.parent_id !== undefined) {
                    formData.append('parent_id',  this.category.parent_id);
                }

                formData.append('metaTitle', this.category.meta_title);
                formData.append('metaDescription', this.category.meta_description);
                for (let i = 0; i < this.category.image.length; i++) {
                    formData.append('image[]', this.category.image[i]);
                }
                formData.append('order_id', this.category.order_id);
                formData.append('order_product', this.category.order_product);

                this.$store.dispatch('saveCategory', formData);
                return this.category;
            },
            validate() {
                return this.valid
                    && (this.category.description != "" && this.category.description != null)
                    && (this.category.image != "" && this.category.image != null)
            }
        },
        beforeCreate() {
            this.$store.dispatch('getItemsSubcategory');
        }
    }
</script>

<style scoped>

</style>