<template>
    <v-container>
        <title-page>Создать товар</title-page>
        <v-form v-model="valid">
            <v-row>
                <product-form v-model="product"></product-form>
                <v-col
                    cols="12"
                >
                    <v-btn
                            color="primary"
                            @click="save()"
                    >
                        Сохранить
                    </v-btn>
                    <v-alert class="mt-4" v-if="getError != null" type="error">{{ getError }}</v-alert>
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>

<script>
    import TitlePage from "@/components/TitlePage";
    import ProductForm from "@/components/ProductForm";

    export default {
        name: "Create",
        components: {ProductForm, TitlePage},
        data: () => ({
            valid: false,
            product: {
                name: '',
                image: '',
                description: '',
                price: null,
                category_id: null,
                specifications: [
                    {
                        title: '',
                        children: [
                            {
                                key: '',
                                value: ''
                            }
                        ]
                    }
                ],
                meta_title: '',
                meta_description: '',
                order_id: 200
            }
        }),
        computed: {
            getError() {
                return this.$store.getters.getError;
            }
        },
        methods: {
            save() {
                if (!this.validate()) {
                    this.$store.commit('setError', "Заполните все обязательные поля");
                    return;
                }
                let formData = new FormData();

                formData.append('name', this.product.name);
                formData.append('description', this.product.description);

                formData.append('metaTitle', this.product.meta_title);
                formData.append('metaDescription', this.product.meta_description);
                for (let i = 0; i < this.product.image.length; i++) {
                    formData.append('image[]', this.product.image[i]);
                }
                formData.append('price', this.product.price);
                formData.append('specifications', JSON.stringify(this.product.specifications));
                formData.append('category_id', this.product.category_id);
                formData.append('order_id', this.product.order_id);

                this.$store.dispatch('saveProduct', formData);
                return this.product;
            },
            validate() {
                return this.valid
                    && (this.product.description != "" && this.product.description != null)
                    && (this.product.image != "" && this.product.image != null)
            }
        },
        beforeCreate() {
            this.$store.dispatch('getCategories');
        }
    }
</script>

<style scoped>

</style>